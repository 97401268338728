import "./App.css"
import { useEffect, useRef, useState } from 'react';
import diabeticRetinopathyOverlay from './diabetic_retinopathy.png';
import cataractOverlay from './cataract.png';
import macularDegenerationOverlay from './macular_degeneration.png';
import glaucomaOverlay from './glaucoma.png';

function App() {
  const videoRef = useRef(null);
  const [selected, setSelection] = useState("diabeticRetinopathy");

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: { ideal: "environment" } },
        audio: false
      })
      .then(stream => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.onloadedmetadata = video.play
      })
      .catch(err => {
        console.error(err);
      })
  }

  function getOverlay() {
    switch (selected) {
      case 'diabeticRetinopathy':
        return diabeticRetinopathyOverlay
      case 'cataract':
        return cataractOverlay
      case 'macularDegeneration':
        return macularDegenerationOverlay
      case 'glaucoma':
        return glaucomaOverlay
      default:
        return undefined
    }
  }

  useEffect(() => {
    getVideo()
  }, [videoRef])

  return (
    <div className='container'>
      <div className='camera'>
        <video style={{ width: '100%', height: '100%' }} ref={videoRef} playsinline=""></video>
      </div>
      <div className='overlay'>
        <img src={getOverlay()} alt="Disease Filter" style={{ width: '101%', height: '101%' }} />
      </div>

      <form>
        <div className="radio">
          <label>
            <input type="radio" value="diabeticRetinopathy"
              checked={selected === 'diabeticRetinopathy'}
              onChange={() => { setSelection('diabeticRetinopathy') }} />
            Diabetic Retinopathy
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="cataract"
              checked={selected === 'cataract'}
              onChange={() => { setSelection('cataract') }} />
            Cataract
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="macularDegeneration"
              checked={selected === 'macularDegeneration'}
              onChange={() => { setSelection('macularDegeneration') }} />
            Macular Degeneration
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="glaucoma"
              checked={selected === 'glaucoma'}
              onChange={() => { setSelection('glaucoma') }} />
            Glaucoma
          </label>
        </div>
      </form>
    </div>
  );
}

export default App;
